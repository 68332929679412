<template>
  <div class="studyVideo">
    <div class="video">
      <video id="video" :src="indexData.videoUrls" controls="controls"></video>
    </div>
    <div class="tips">温馨提示：{{ tips }}</div>
  </div>
</template>

<script>
import { toRegister, gloabalCount } from "@/utils/common.js";
import { rubTopicInfoUrl, myLastAnswersUrl, updateVideoUrl } from "./api.js";
import { resultArrMap } from "./map.js";
var moment = require("moment");
export default {
  name: "answerRecord",
  data() {
    return {
      id: "",
      oFrom: "",
      resultArrMap,
      isDialog: false,
      answerData: {},
      oList: [],
      indexData: {},
      isVideo: false,
      tips: "",
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    this.oFrom = this.$route.query.oFrom;
    if (this.oFrom == "isRecord") {
      this.tips = "观看完视频后自动跳转答题页面";
    }
    if (this.oFrom == "isCamera") {
      this.tips = "观看完视频后自动跳转拍照页面";
    }
  },
  async mounted() {
    // gloabalCount("", 20, 1);
    await this.getIndexData();
    let _this = this;

    var video = document.getElementById("video");
    video.addEventListener(
      "ended",
      function () {
        //监听到播放结束后，在此处可调用自己的接口
        _this.updateVideo();
      },
      false
    );
  },
  methods: {
    async updateVideo() {
      if (this.oFrom == "isRecord") {
        this.$router.replace({
          name: "question",
          query: {
            code: 2,
          },
        });
      } else if (this.oFrom == "isCamera") {
        this.$router.replace({
          name: "rubbishSortCamera",
        });
      }

      //   let params = {
      //     houseId: this.roomId,
      //     topicId: this.indexData.id,
      //     userId: this.userId,
      //   };
      //   let res = await this.$axios.post(
      //     `${updateVideoUrl}`,
      //     this.$qs.stringify(params)
      //   );
      //   if (res.code == 200) {
      //     this.getIndexData();
      //   }
    },
    lookVideo() {
      this.isVideo = true;
    },
    async getIndexData() {
      let res = await this.$axios.get(rubTopicInfoUrl, {
        params: {
          houseId: this.roomId,
          userId: this.userId,
        },
      });
      if (res.code === 200) {
        this.indexData = res.data;
      } else {
      }
    },

    toClose() {
      this.isDialog = false;
      this.isVideo = false;
    },
  },
};
</script>
<style lang="less" scoped>
.studyVideo {
  min-height: 100vh;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .video {
    width: 100%;
    height: 400px;
    video {
      width: 100%;
      height: 100%;
    }
  }
  .tips {
    padding: 10px;
    font-size: 30px;
  }
}
</style>
<style lang="less">
.studyVideo {
}
</style>
